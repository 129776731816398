<template>
    <div>
        Add Your Payment Method
    </div>
</template>

<script>
export default {  
  
}
</script>
 